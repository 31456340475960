/* Highlighter Blue */

a.highlighterBlue {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
}

a.highlighterBlue::after {
  content: "";
  opacity: 0.5;
  background: #8eb0d9;
  position: absolute;
  display: inline-block;
  height: 0.5em;
  width: calc(100% + 4px);
  left: 50%;
  bottom: 0.05em;
  transform: translateX(-50%);
  z-index: -1;
  transition: 0.3s;
}

a.highlighterBlue:hover:after {
  height: 1.2em;
}

/* Highlighter Yellow */

a.highlighterYellow {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
}

a.highlighterYellow::after {
  content: "";
  opacity: 0.5;
  background: #f8d797;
  position: absolute;
  display: inline-block;
  height: 0.5em;
  width: calc(100% + 4px);
  left: 50%;
  bottom: 0.05em;
  transform: translateX(-50%);
  z-index: -1;
  transition: 0.3s;
}

a.highlighterYellow:hover:after {
  height: 1.2em;
}
